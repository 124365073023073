<script>
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header';
import ExpressionBuilder from "@/components/widgets/queryBuilder";
import { required } from "vuelidate/lib/validators";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import EmptyList from '@/components/widgets/empty_list'
import audienceFields from "@/components/widgets/audienceFields.vue";

import {
	audienceMethods,
  propertyMethods,
  projectMethods,
  customerMethods,
  interactionMethods,
  campaignMethods,
  userMethods,
  } from "@/state/helpers";

/**
 * Audience component
 */
export default {
  page: {
    title: "Audience",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, ExpressionBuilder, Multiselect, EmptyList, audienceFields },
  data() {
    return {
      title: '',
      items: [],
      audience:{},
      query: {
        type: "or",
        children: []
      },
      disabled: false,
      submitted: false,
      frequencies: [
        {name: this.$t("audiences.audience_conditions_frequency_30"), id: 30},
        {name: this.$t("audiences.audience_conditions_frequency_60"), id: 60},
        {name: this.$t("audiences.audience_conditions_frequency_90"), id: 90},
      ],
      properties_products: [],
      properties_crm: [],
      affinity_Types: [],
      categories: [],
      rfm_segments: [],
      pageLoaded: false,
      empty_config:{
        "title" : this.$t("audiences.audiences_conditions_empty"),
        "subtitle" : this.$t("audiences.audiences_conditions_empty_subtitle"),
        "buttonText" : this.$t("audiences.audience_conditions_add_condition"),
        icon: "fa-cubes",
        academy: "https://academy.gopersonal.ai/guia-de-usuario/segmentacion/audiencias#condiciones"
      },
      custom_interactions:[],
      showAudienceFields: false,
      campaigns:[],
      hosts:[],
      contactLists: []
    };
  },
  validations: {
    audience: {
        name: { required},
        description: { required },
        frequency: { required },
    }
  },
  mounted(){
    const audienceId = this.$route.query?.id;
    this.title = audienceId ? '' : this.$t("audiences.new_audience")
    if(audienceId){
      const loader = this.$loading.show();
      this.getAudience(audienceId).then((audience)=>{
        this.audience = audience.data;
        this.query = this.audience.rules;
        this.audience.frequency = this.frequencies.filter(f=> f.id == this.audience.frequency)[0];
        this.title = this.audience.name;
        this.pageLoaded = true;
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t("audiences.get_audience_error"),title:  this.$t("audiences.title") });
      }).finally(()=>{
        loader.hide();
      })
    }else{
      this.pageLoaded = true;
    }
    this.loadProperties();
    this.loadCategories();
    this.loadRFM();
    this.loadCustomInteractions()
    this.loadCampaigns();
    this.loadHosts();
    this.loadContactLists();
  },
  computed:{
    query_empty(){
      return this.query.children.length == 0
    }
  },
  methods:{
    ...audienceMethods,
    ...propertyMethods,
    ...projectMethods,
    ...customerMethods,
    ...interactionMethods,
    ...campaignMethods,
    ...userMethods,

    onCancelClicked(){
        this.$router.push({
            path: "/audiences",
            }
        ).catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error)
        });
    },
    async onConfirmClicked(){
      this.submitted = true;
        
      this.$v.$touch();

      if (this.$v.$invalid) {
				return;
			}
      
      if(this.query_empty){
        return;
      }

      let loader = this.$loading.show();
			
			const payload = {
        project: localStorage.getItem('current_project'),
        name: this.audience.name,
        description:this.audience.description,
        rules: this.query,
        frequency: this.audience.frequency.id
      }

      const audienceId = this.$route.query?.id;
      if(!audienceId){
        this.createAudience(payload).then(()=>{
          this.$notify({ type: 'success', text: this.$t("audiences.create_audience_success"),title:  this.$t("audiences.title") });
          this.$router.push({
              path: "/audiences",
              }
          ).catch((error) => {
              // eslint-disable-next-line no-console
              console.log(error)
          });
        }).catch(()=>{
          this.$notify({ type: 'error', text: this.$t("audiences.create_audience_error"),title:  this.$t("audiences.title") });
        }).finally(()=>{
          loader.hide();
        })
      }else{
        payload._id = audienceId;

        this.updateAudience(payload).then(()=>{
          this.$notify({ type: 'success', text: this.$t("audiences.update_audience_success"),title:  this.$t("audiences.title") });
          this.$router.push({
              path: "/audiences",
              }
          ).catch((error) => {
              // eslint-disable-next-line no-console
              console.log(error)
          });
        }).catch(()=>{
          this.$notify({ type: 'error', text: this.$t("audiences.update_audience_error"),title:  this.$t("audiences.title") });
        }).finally(()=>{
          loader.hide();
        })
      }
			
    },
    addFixedProperties(){
      this.properties_products.unshift({
        type : 'product_price',
        name: this.$t("audiences.audience_conditions_fields_interaction_products_with_price"),
        column_name: 'product_price'
      });
      this.properties_products.unshift({
        type : 'product_category',
        name: this.$t("audiences.audience_conditions_fields_interaction_products_with_category"),
        column_name: 'product_category'
      });
      this.properties_products.unshift({
        type : 'any',
        name: this.$t('journeys.journey_workflow_components_wait_interaction_any'),
        column_name: 'any'
      });
    },
    loadContactLists(){
      
      let loader = this.$loading.show();
      const params = {
				q : `where[project]=${localStorage.getItem('current_project')}&limit=100&where[status]=completed`
			}
			this.getContactLists(params).then((lists)=>{
        if(lists&& lists.data){
          this.contactLists= lists.data;
        }
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t("contact_lists.get_contact_lists_error"),title:  this.$t("contact_lists.title") });
      }).finally(()=>{
        this.isLoading = false;
        loader.hide();
      });
    
    },
    loadProperties(){
      let loader = this.$loading.show();
      const params = {
        q : `project=${localStorage.getItem("current_project")}`
      }
      this.getProperties(params).then((properties)=>{
        
        this.properties_products = properties.filter(p=> p.entity == 'product');
        this.properties_crm = properties.filter(p=> p.entity == 'customer');
        this.affinity_Types = this.properties_products.filter(p=> p.type== 'list');
        this.affinity_Types.push({
          type : 'product_category',
          name: this.$t("audiences.audience_conditions_fields_interaction_products_with_category"),
          column_name: 'product_category'
        });

        this.addFixedProperties();
        
        
      }).catch(()=>{

      }).finally(()=>{
        loader.hide();
      })
    },
    loadCategories(){
      
    let loader = this.$loading.show();
      const params = {
        q : `project=${localStorage.getItem("current_project")}`
      }
      
      this.getItemsCategories(params).then((categories)=>{
        this.categories = categories;
      }).catch(()=>{

      }).finally(()=>{
        loader.hide();
      })
    },
    loadRFM(){
      
      let loader = this.$loading.show();
        const params = {
          q : `project=${localStorage.getItem("current_project")}`
        }
        
        this.getRFMSegments(params).then((rfm_segments)=>{
          this.rfm_segments = rfm_segments;
        }).catch(()=>{
  
        }).finally(()=>{
          loader.hide();
        })
      },

      onCreateConditionClicked(){
        this.showAudienceFields = true;
        /*this.query.children.push({
            field: null,
            operator: null,
            term: null
          });*/
      },
      
      onCreateGroupClicked(){
        this.query.children.push({
            group: true,
            type: "and",
            children: [
              {
                field: null,
                operator: null,
                term: null
              }
            ]
        })
      },
      loadCustomInteractions(){
      const params = {
        q : `where[project]=${localStorage.getItem('current_project')}`
      }
      this.getInteractions(params).then((res)=>{
        if(res.data){
          this.custom_interactions = res.data;  
        }
      })
    },
    loadCampaigns(){
      const params = {
        q : `where[project]=${localStorage.getItem('current_project')}&limit=50`
      }
      this.getCampaigns(params).then((res)=>{
        if(res.data){
          this.campaigns = res.data;  
        }
      })
    },

    loadHosts(){
      const params={
				q: `where[role]=host&limit=50`,
			}
			this.getUsers(params).then((res)=>{
      if(res.data){
          this.hosts = res.data;  
        }
      })
    },
    onAudienceFieldSelected(id){
      this.query.children.push({
        field: id,
        operator: null,
        term: null,
        times:{},
        new: true
      });
      this.showAudienceFields = false;
    }
  },
  
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body" >
            <div class="row" >
                <div class="col-sm-6">
                    <div class="mb-3">
                        <label for="promotionName">{{$t("audiences.audience_name")}}*</label>
                        <input
                            id="promotionName"
                            v-model="audience.name"
                            name="name"
                            type="text"
                            class="form-control"
                            :placeholder="$t('audiences.audience_name_placeholder')"
                            :class="{ 'is-invalid': submitted && $v.audience.name.$error}"
                        />
                        <div v-if="submitted && !$v.audience.name.required" class="invalid-feedback">
                          {{$t("audiences.audience_name_required")}}
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                  <div class="mb-3">
                      <label for="promotionName">{{$t("audiences.audience_description")}}*</label>
                      <input
                          :disabled="disabled"
                          id="promotionName"
                          v-model="audience.description"
                          name="name"
                          type="text"
                          class="form-control"
                          :placeholder="$t('audiences.audience_description_placeholder')"
                          :class="{ 'is-invalid': submitted && $v.audience.description.$error}"
                      />
                      <div v-if="submitted && !$v.audience.description.required" class="invalid-feedback">
                        {{$t("audiences.audience_description_required")}}
                      </div>
                  </div>
                </div>
                <div class="col-sm-12 mb-3">
                  <div class="mb-0 d-inline-flex align-items-baseline w-100">
                      <i class="bx bx-stopwatch font-size-16"></i>
                      <label for="promotionName">{{$t("audiences.audience_frequency")}}</label>
                      <multiselect
                        :options="frequencies" 
                        v-model="audience.frequency"
                        track-by="id"
                        label="name"
                        selectLabel=""
                        deselectLabel=""
                        class=" ms-2 w-25"
                        :placeholder="$t('audiences.audience_frequency_placeholder')"
                      />
                  </div>
                  <div v-if="submitted && !$v.audience.frequency.required" class="invalid-feedback d-block">
                    {{$t("audiences.audience_frequency_required")}}
                  </div>
                </div>
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <div class="card" v-if="pageLoaded && query.children.length == 0">
          <div class="card-body">
            <EmptyList :config="empty_config" v-if="pageLoaded && query.children.length == 0" @onButtonClicked="onCreateConditionClicked"/>
          </div>
        </div>
        <div class="card" v-if="pageLoaded && query.children.length> 0">
          <div class="card-body">
            <div class="col-sm-12" v-if="pageLoaded && query.children.length > 0">
                  <label for="promotionName">{{$t("audiences.audience_conditions")}}</label>
                  <ExpressionBuilder :query="query" :conditionType="'audienceType'" :properties_products="properties_products" :affinity_Types="affinity_Types" :properties_crm="properties_crm" :categories="categories" :rfm_segments="rfm_segments" :custom_interactions="custom_interactions" :campaigns="campaigns" :hosts="hosts" :contactLists="contactLists"/>
                  <div v-if="submitted && query_empty" class="invalid-feedback d-block">
                    {{$t("audiences.audience_conditions_required")}}
                  </div>
              </div>
          </div>
        </div>
        <b-modal v-model="showAudienceFields" hide-footer :title="$t('audiences.audience_conditions')">
          <audienceFields @onValueClicked="onAudienceFieldSelected" :custom_interactions="custom_interactions" />
        </b-modal>
      </div>
      <!-- end col -->
      <div class="row mt-4">
        <div class="col-sm-6">
        </div>
        <!-- end col -->
        <div class="col-sm-6">
          <div class="text-end">
            <b-button variant="light" class="w-md mb-3" @click="onCancelClicked">{{$t("common.cancel")}}</b-button>
            <b-button variant="primary" class="w-md ms-3 mb-3" @click="onConfirmClicked">{{$t("common.confirm")}}</b-button>
          </div>
        </div>
			<!-- end col -->
		</div>
    <!-- end row -->
    </div>
    
  </Layout>
</template>
